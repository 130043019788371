<template>
  <div>
    <b-card class="mt-6" v-if="pending_members_data">
      <div class="mx-auto d-flex justify-content-center mb-3">
        <b-spinner small type="grow"></b-spinner>
      </div>
    </b-card>

    <b-card v-else class="mt-6" header-border-variant="white" no-body>
      
      <b-table
        id="company_summary-table"
        style="width: 100%; table-layout: fixed;"
        class="table-striped"
        :fields="fields"
        :items="items"
        head-variant="light"
        ref="company_summary_table"
      >

      </b-table>
    </b-card>
  </div>
    
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import {
  SET_USER_PREFS
} from '@/core/services/store/common.module';
import store from '@/core/services/store';


export default {
  name: 'DashboardCompanySummaryCard',
  components: {

  },
  props: ['start_date','end_date'],
  data() {
    return {
      items: [],
      
      fields: [
        {
          visible: true,
          key: 'name',
          label: this.$t('COMPANY.NAME'),
          sortable: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          visible: true,
          key: 'cnt',
          label: this.$t('COMPANY.COUNT'),
          sortable: true,
          tdClass: 'td-short',
          thClass: 'td-short'
        },
      ],
      pending_members_data: true,
      type: 'members',
      totals: {}
    };
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'isAuthenticated', 'initComplete', 'user_prefs']),
  },
  mounted() {

    if (this.user_prefs) {
      if (this.user_prefs.dashboard) {
        this.type = this.user_prefs.dashboard.total;
      }
      else {
        this.user_prefs.dashboard = {
          total: 'members'
        };
      }
    }
    else {
      this.user_prefs = {
        dashboard: {
          total: 'members'
        }
      };
    }
  },
  watch: {
    start_date() {
      this.load_data();
    },
    end_date() {
      this.load_data();
    },
    type() {
      this.pending_members_data = true;

      this.user_prefs.dashboard.total = this.type;

      store.dispatch(SET_USER_PREFS, this.user_prefs);

      this.load_data();
    },
    
    currentCompanyId() {
      this.load_data();
    },
    currentPeriodId(newValue, oldValue) {
      if (newValue != -1) {
        this.load_data();
      }
    },
  },
  methods: {

    async load_data() {
      if (this.type === 'members' || this.type === 'date_memberships') {
        this.items = await this.load_companies_summary_stats_date(this.currentCompanyId, this.start_date, this.end_date);
      }
      else if (this.type === 'period_memberships') {
        this.items = await this.load_companies_summary_stats_period(this.currentCompanyId, this.currentPeriodId);
      }
      else {
        this.items = await this.load_companies_summary_stats_period(this.currentCompanyId, this.currentPeriodId);
      }
      
    },

    update_prefs() {
      store.dispatch(SET_USER_PREFS, this.user_prefs);
    },

    async load_companies_summary_stats_date(company_id, start_date, end_date) {
      try {
        
        const res = await axios.get(`/company/summary/${company_id}?start_date=${start_date}&end_date=${end_date}`);

        this.pending_members_data = false;

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error('load_companies_summary_stats_date error', err);
      }

      return [];
    },

    async load_companies_summary_stats_period(company_id, period_id) {
      try {
        
        const res = await axios.get(`/company/summary/${company_id}?period_id=${period_id}`);

        this.pending_members_data = false;

        if (res.status === 200) {
          
          return res.data;
        }
      }
      catch (err) {
        console.error('load_companies_summary_stats_period error', err);
      }

      return [];
    }
  }
};
</script>

<style lang="scss" scoped>

:deep .td-short {
  width: 75px;
}

:deep .td-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  max-width: 75%;
}
  
</style>
